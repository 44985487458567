import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  return (
    <>
      <div className="container-fluid bg-header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">COMICOIN</div>
            <div className="col-md-12">Suscribe and claim after $COMIC</div>
          </div>
        </div>
      </div>
    </>
  );
}
